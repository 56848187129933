
export const enviarInfo = async informacion => {
  const response = await fetch(
    `${process.env.VUE_APP_STARK_API_URL}/${informacion.familia == 2 ? 'pilot' : 'tecnom'}/generar-lead`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'Application/json'
      },
      body: JSON.stringify(informacion)
    }
  )

  return response.json()
}
