<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent height="500px" max-width="800px">
            <v-form ref="form" v-model="valid" lazy-validation>

                <v-card>
                    <v-card-title>
                        <span v-if="screen >= 1024" class="text-h5">Conoce más sobre este vehículo </span>
                        <span v-if="screen < 1024" style="font-size:16px">Conoce más sobre este vehículo </span>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="CloseDialog()">
                            <v-icon small color="#808080">fas fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <!-- <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
                <v-icon>fas fa-times</v-icon>
            </v-btn> -->
                    <hr>
                    <v-card-title>
                        <span v-if="screen >= 1024" class="text-h6">Solicite cotización del vehículo </span>
                        <span v-if="screen < 1024" style="font-size:14px">Solicite cotización del vehículo </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="nombre" label="Nombre*" required style="margin-top:-20px"
                                        :rules="[rules.requiredNombre]"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="apellido" label="Apellido*" style="margin-top:-20px" required
                                        :rules="[rules.requiredApellido]"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="email" label="Email*" required style="margin-top:-20px"
                                        :rules="[rules.requiredEmail, rules.email]"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="telefono" label="Telefono*" style="margin-top:-20px" required
                                        :rules="[rules.requiredTelefono]"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea label="Mensaje" v-model="mensaje" auto-grow rows="1" row-height="15"
                                        required style="margin-top:-20px" :rules="[rules.requiredMensaje]">
                                    </v-textarea>
                                </v-col>
                                <v-col cols="12" style="margin-top:-20px">
                                    <small>*indica campo obligatorio</small>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-col cols="12">
                            <v-checkbox
                                label="Autorizo a la  concesionaria a almacenar mis datos, así como recibir información vía telefónica o email."
                                color="#808080" item-color="#808080" dense @click="check = true"
                                style="margin-top:-10px" required :rules="[rules.requiredAutorizacion]"></v-checkbox>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#1B75BA " text @click="CloseDialog()" style="margin-top:-40px">
                            <span style="color:#1B75BA">Cancelar</span>

                        </v-btn>
                        <v-btn color="#1B75BA " text @click="enviar()" style="margin-top:-40px">
                            <span style="color:#1B75BA">Enviar</span>

                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-alert outlined type="success" text :value="alert_success">
            <div class="text-h6">
                Exito
            </div>
            <div>Gracias . Su mensaje vue enviado con exito</div>
        </v-alert>
    </v-row>
</template>

<script>
import moment from 'moment';
import { enviarInfo } from '../services/tecnom';
import { getMarcasUsados } from '../services/vehiculos';

export default {
    props: {
        dialog: Boolean,
        vehiculo: Object
    },
    data: () => ({
        nombre: '',
        apellido: '',
        telefono: '',
        email: '',
        mensaje: '',
        rules: {
            requiredNombre: value => !!value || 'Nombre Obligatorio',
            requiredApellido: value => !!value || 'Apellido Obligatorio',
            requiredEmail: value => !!value || 'Email Obligatorio',
            requiredTelefono: value => !!value || 'Telefono Obligatorio',
            requiredMensaje: value => !!value || 'Mensaje Obligatorio',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Email Invalido'
            },
            requiredAutorizacion: value => {

                return !!value || 'Se requiere autorización'
            }
        },
        alert_success: false,
        check: false,
        valid: true,
        screen: screen.width,

        marcas: [],
        marca: {}


    }),

    methods: {

        validar() {
            return this.$refs.form.validate()
        },

        async enviar() {

            if (!this.$refs.form.validate()) return

            const host = window.location.host;
            const match = host.match(/\.([^.]+)\./);
            const keyword = match ? match[1] : null;
            let familia = 1
            if (keyword == 'eurodycar') {
                familia = 2
            }

            if (this.validar()) {
                let mensaje = {
                    nombre: this.nombre,
                    email: this.email,
                    apellido: this.apellido,
                    telefono: this.telefono,
                    estado: 'Pendiente',
                    comentario: this.mensaje,
                    observacion: '',
                    fecha: fbTime.fromDate(new Date())
                }
                db.collection('mensajes').add(mensaje)
                    .then(() => {
                    })
            }

            await enviarInfo({
                familia,
                fecha: moment.utc().toDate(),
                nombre: this.nombre,
                apellido: this.apellido,
                email: this.email,
                telefono: this.telefono,
                mensaje: this.mensaje,
                vehiculos: [{
                    marca: this.marca,
                    modelo: this.vehiculo.modeloFull || '',
                    anio: this.vehiculo.anio,
                    detalle: `Color: ${this.vehiculo.color} - Patente: ${this.vehiculo.patente}`
                }],
                uri: this.$route.path
            }).then(res => {
                if (res.exito) {
                    this.alert_success = true
                }
            })
        },

        CloseDialog() {
            this.$emit("update:dialog", false)
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
        }
    },

    watch: {
        async dialog(val) {
            if (val) {
                this.marcas = await getMarcasUsados()
                this.marca = this.marcas.find(marca => marca.id == this.vehiculo.marcaRef.id)?.nombre
            }
        }
    }


}
</script>